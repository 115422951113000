<template>
  <div>
    <Header />
    <div class="container overflow-hidden mt-4">
      <div class="row">
        <section class="un-details-blog col-lg-7 col-sm-12 col-md-7 col-xs-12">
          <div class="head">
            <div class="cover_img">
              <picture>
                <source
                  v-if="ArticleDetails._embedded['wp:featuredmedia']"
                  :srcset="
                    ArticleDetails._embedded['wp:featuredmedia'][0].source_url
                  "
                  style="height: 400px"
                  type="image/webp"
                />
                <img
                  v-if="ArticleDetails._embedded['wp:featuredmedia']"
                  :src="
                    ArticleDetails._embedded['wp:featuredmedia'][0].source_url
                  "
                  style="height: 400px"
                  alt=""
                />
              </picture>
              <div
                class="btn-xs-size bg-success text-white rounded-pill position-absolute start-0 top-0 m-3"
              >
                {{ ArticleDetails?._embedded?.["wp:term"][0][0]?.name }}
              </div>
            </div>
          </div>
          <div class="body">
            <div class="d-flex justify-content-start align-items-center">
              <div class="btn-xs-size bg-dark text-white rounded-pill">
                {{ ArticleDetails?._embedded?.["wp:term"][0][0]?.name }}
              </div>
            </div>
            <div class="title-blog mt-3">
              <div class="others">
                <div class="time">
                  <i class="ri-time-line"></i>
                  <span>{{
                    moment(ArticleDetails.created_at).format("MMMM Do YYYY")
                  }}</span>
                </div>
                <div class="views">
                  <i class="ri-article-line"></i>
                  <span>{{ ArticleDetails.est_time_read }}</span>
                </div>
              </div>
              <h2>{{ ArticleDetails.title?.rendered }}</h2>
            </div>
            <div
              class="description mb-4"
              v-html="ArticleDetails.content?.rendered"
            ></div>

            <h5>Share This article</h5>

            <div class="share-network-list mt-3">
              <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{ backgroundColor: network.color }"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
                <i :class="network.icon"></i>
                <span>{{ network.name }}</span>
              </ShareNetwork>
            </div>
          </div>
        </section>

        <div class="footer col-lg-5 col-sm-12 col-md-5 col-xs-12">
          <div class="un-title-default px-0 margin-b-20">
            <div class="text">
              <h2>Other similar posts</h2>
            </div>
            <div class="un-block-right">
              <a
                href="page-news-list.html"
                class="icon-back visited"
                aria-label="iconBtn"
              >
                <i class="ri-arrow-drop-right-line"></i>
              </a>
            </div>
          </div>
          <ul class="nav flex-column">
            <article
              class="nav-item item-blog-list"
              v-for="(similar, index) in similarArticle.slice(0, 10)"
              :key="index"
            >
              <a class="nav-link" :href="'/article-detail/' + similar.slug">
                <div class="image-blog">
                  <picture>
                    <source
                      v-if="similar._embedded['wp:featuredmedia']"
                      :srcset="
                        similar._embedded['wp:featuredmedia'][0].source_url
                      "
                      type="image/webp"
                    />
                    <img
                      v-if="similar._embedded['wp:featuredmedia']"
                      :src="similar._embedded['wp:featuredmedia'][0].source_url"
                      alt=""
                    />
                  </picture>
                  <div class="text-blog">
                    <h2>
                      {{ similar.title?.rendered }}
                    </h2>
                    <div class="others">
                      <div class="time">
                        <i class="ri-time-line"></i>
                        <span>{{
                          moment(similar.created_at).format("MMMM Do YYYY")
                        }}</span>
                      </div>
                      <div class="views">
                        <i class="ri-eye-line"></i>
                        <span>{{ similar.est_time_read }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div v-html="Wordpress.content?.rendered"></div> -->
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import moment from "moment";

export default {
  name: "ArticleDetail",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      ArticleDetails: null,
      moment: moment,
      similarArticle: [],
      sharing: {
        url: "",
        title: "",
        description: "",
        quote:
          "We always provide the best information about crypto - Dinodapps",
        hashtags: "",
        twitterUser: "dinowalletapp",
      },
      networks: [
        {
          network: "baidu",
          name: "Baidu",
          icon: "fah ri-baidu-line",
          color: "#2529d8",
        },
        {
          network: "email",
          name: "Email",
          icon: "fah ri-mail-line",
          color: "#333333",
        },
        {
          network: "evernote",
          name: "Evernote",
          icon: "fah ri-evernote-line",
          color: "#2dbe60",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fah ri-facebook-line",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fah ri-linkedin-line",
          color: "#007bb5",
        },
        {
          network: "messenger",
          name: "Messenger",
          icon: "fah ri-messenger-line",
          color: "#0084ff",
        },
        {
          network: "pinterest",
          name: "Pinterest",
          icon: "fah ri-pinterest-line",
          color: "#bd081c",
        },
        {
          network: "quora",
          name: "Quora",
          icon: "fah ri-quora",
          color: "#a82400",
        },
        {
          network: "reddit",
          name: "Reddit",
          icon: "fah ri-reddit-line",
          color: "#ff4500",
        },
        {
          network: "skype",
          name: "Skype",
          icon: "fah ri-skype-line",
          color: "#00aff0",
        },
        {
          network: "sms",
          name: "SMS",
          icon: "fah ri-message-line",
          color: "#333333",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fah ri-telegram-line",
          color: "#0088cc",
        },
        {
          network: "tumblr",
          name: "Tumblr",
          icon: "fah ri-tumblr-line",
          color: "#35465c",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fah ri-twitter-line",
          color: "#1da1f2",
        },
        {
          network: "weibo",
          name: "Weibo",
          icon: "fah ri-weibo-line",
          color: "#e9152d",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fah ri-whatsapp-line",
          color: "#25d366",
        },
        {
          network: "wordpress",
          name: "Wordpress",
          icon: "fah ri-wordpress",
          color: "#21759b",
        },
        {
          network: "xing",
          name: "Xing",
          icon: "fah ri-xing-line",
          color: "#026466",
        },
      ],
      queryOptions: {
        _embed: true, //Response should include embedded resources.
      },
    };
  },
  mounted() {
    this.getArticleDetails();
    this.$nextTick(() => {
      let twitterScript = document.createElement("script");
      twitterScript.setAttribute(
        "src",
        "https://platform.twitter.com/widgets.js"
      );
      document.head.appendChild(twitterScript);
    });
  },
  methods: {
    async getArticleDetails() {
      let ids = this.$route.params.id;
      await axios
        .get(
          "https://api.webooswap.com/wordpress/?rest_route=/wp/v2/posts?slug=" +
            ids +
            "&_embed=wp:term",
          {
            params: this.queryOptions,
          }
        )
        .then((response) => {
          this.ArticleDetails = response.data[0];
          this.sharing.url =
            "https://dinodapps.com/article-detail/" + response.data[0].slug;
          this.sharing.title = "New Article: " + response.data[0].title;
          this.sharing.description =
            "See The New Hot Crypto Article From DinoDapps: " +
            response.data[0].title;
          this.sharing.hashtags = response.data[0].categories[0];
          this.$mixpanel.track("Visit Article " + response.data[0].title);
          axios
            .get(
              "https://api.webooswap.com/wordpress/?rest_route=/wp/v2/posts/?categories=" +
                response.data[0].categories[0] +
                "&per_page=5",
              {
                params: this.queryOptions,
              }
            )
            .then((res) => {
              this.similarArticle = res.data;
            });
        });
    },
  },
};
</script>

<style>
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 1000px;
  margin: auto;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  text-decoration: none;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 5px 10px;
  flex: 1 1 0%;
  font-weight: 500;
}

.item-blog-list .nav-link .image-blog img {
  width: 160px !important;
  height: 90px !important;
}
</style>