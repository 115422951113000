<template>
  <div>
    <Header/>
    <VueRecaptcha
        sitekey="6Lcfly0lAAAAAId6o8xQilFwq9LnHUb9oetJtpmy"
        :load-recaptcha-script="true"
        @verify="onVerify"
        @expired="onExpired"
        ref="recaptcha"
      ></VueRecaptcha>
      <Footer/>
  </div>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import Header from "@/components/Header.vue";
import Footer from "../components/Footer.vue"
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "LinkedPage",
  components: {
    VueRecaptcha,
    Header,
    Footer
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
    onVerify: function (response) {
      let newKey = response;
      axios
          .get("https://iptools-4.top10vpn.com/ip/?genToken=1")
          .then((res) => {
            axios.post("https://api.webooswap.com/bullnium/api/dino/shill-open", {
              code: this.$route.params.code,
              ip: res.data.ip,
              agent: navigator.userAgent,
              captcha_token: newKey,
              type: 'DAPPS VISIT'
            },{
              headers: {
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNjc4MzEzYThiZDEzYTI1MDY4NzZhYzQ0ZGJlOWY5NmZmZmMyNWRhMTNkNDQwOTdjMWVkOTgxMWFmOWMyOGI3NDk5NDg5YjkyYTg1MjdkZWEiLCJpYXQiOjE2Nzc5MDY0OTQuMjEzNTIsIm5iZiI6MTY3NzkwNjQ5NC4yMTM1MjQsImV4cCI6MTQ2Mzc5MDY0OTQuMTk5NzAxLCJzdWIiOiI2NSIsInNjb3BlcyI6W119.ONRmE7CeqM5jIgAkpSyd78PtETZPty_0yJjmJ2QExhdAb7tUMbRA1dEQ4MH7Q-SU3n6RLzgiND3iyWpEfUggOFJhREUrrHSrzfWVU8ZOKIZGnBOQWrKTYqdbByrnXMwjTUY8lhkpqU4INWtiGxBOTCGueRES3VEi52j_s9Ar69fnBrzfQygzGPC0IvfU-m-Thq5j4WEml_4LarjuO3LssOPD6qX_gFu5b2-YEvoiuh6uVXxHvKHa95mAmQTBGADb8Zi2nHnwUXGOs4tLLriaTHG0MAYh1AhnnfG3IgbnuZMGZzSt163BS-YZR1XRacfSsgpgIIjOpR0z-nFTOA4PirFTtTaH9W8xq5dmRdCjRWCQ45pcLexFOm0M6pR7hL9gVn1tSbD2tI10VpPbLlEowz80iGUpfgEYfyH0ENlW7LFB_phv68z5IVMcU18XvuAsIgE5p9-ABb2vjnIlV-Qy6Ijkpjb8on1-0Ck2k8L7XXkw97V2Aatz0q5DDY9Zb8FZWJfKE4vS0beOXdTNnEF9eD4jT0FyAsrNzNiu43DVOjk9f8dnqVxfTAuc1L7KR_MKtt7t9nFHCq2MEgsbvi5UF6s8HR4BMs4BYUVa2q2DcWkDa3FY_4kcpcNy5qef_rSMpMw4-YqDauKYF6hOAi9UWFHN2AEc7s_ToFcyHtnG1Eg',
                Accept: 'application/json'
              }
            }).then(res2=>{
              if(res2.data.result && res2.data.result.redirect) {
                window.location.href = res2.data.result.redirect 
              }
            });
          });
    },
    onExpired: function () {
      // window.location.reload()
    },
  },
};
</script>

<style>
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 1000px;
  margin: auto;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  text-decoration: none;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 5px 10px;
  flex: 1 1 0%;
  font-weight: 500;
}

.item-blog-list .nav-link .image-blog img {
  width: 160px !important;
  height: 90px !important;
}
</style>